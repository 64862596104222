"use client";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { Avatar, Drawer, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import MenuItem from "antd/es/menu/MenuItem";
import { usePathname } from "next/navigation";
import SvgsIcon from "./svg-icon";
import {
  CreditCard,
  Settings,
  LogOut
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuGroup
} from "@/app/components/dropdown-menu";
import { checkUserAuthentication, deauthenticateUser } from "../functions";

const MainHeader = () => {
  const pathname = usePathname();
  const [open, setOpen] = useState(false);
  const [accountType, setAccountType] = useState<any>(null);
  const [items, setItems] = useState<Array<any>>([]);
  const [finishedUserAuthCheck, setFinishedUserAuthCheck] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const isActiveMenu = (link: any) => {
    if (pathname === "/" && link.href === "/") {
      return "ant-menu-item-selected";
    }
    return pathname.includes(link.href) && link.href !== "/"
      ? "ant-menu-item-selected"
      : "";
  };

  useEffect(() => {
    if (finishedUserAuthCheck) {
      const accountType = localStorage.getItem("accountType");
      setAccountType(accountType);
      if (accountType != "root") {
        if (accountType && accountType.includes("agency")) {
          setItems([
            {
              label: "Dashboard",
              key: "1",
              href: "/",
            },
            {
              label: "Integrations",
              key: "6",
              href: "/integrations",
            }
          ]);
        } else {
          setItems([
              {
              label: "Dashboard",
              key: "1",
              href: "/",
            },
            {
              label: "Customer Retention",
              key: "4",
              href: "/customer-retention",
            }
          ]);
        }
      } else {
        setItems([
          {
            label: "Dashboard",
            key: "1",
            href: "/",
          },
          {
            label: "Lead Generation",
            key: "2",
            href: "/lead-generation",
          },
          {
            label: "Creative Suite",
            key: "3",
            href: "/creative-suite",
          },
          {
            label: "Customer Retention",
            key: "4",
            href: "/customer-retention",
          },
          {
            label: "Review Management",
            key: "5",
            href: "/review-management",
          },
          {
            label: "Integrations",
            key: "6",
            href: "/integrations",
          },
        ]);
      }
    }
  }, [finishedUserAuthCheck]);

  useEffect(() => {
    checkUserAuthentication().then(() => {
      setFinishedUserAuthCheck(true);
    });
  }, []);

  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex lg:flex-row flex-col lg:gap-10 items-center">
        <Link href="#">
          <SvgsIcon type="logo-w-svg" />
        </Link>
        <div className="lg:flex hidden">
          <Menu
            className="main-header"
            mode="vertical"
            selectedKeys={[pathname]}
          >
            {items.map((link) => {
              return (
                <MenuItem key={link.label} className={isActiveMenu(link)}>
                  <Link href={link.href}>{link.label}</Link>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
      <div className="flex gap-7 items-center">
        {/* <div className="cursor-pointer shrink-0">
          <SvgsIcon type="notification-svg" />
        </div> */}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Avatar
              className="!bg-gray-100 !text-black !cursor-pointer shrink-0"
              size={32}
              icon={<UserOutlined />}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56 mr-2">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem>
                <CreditCard className="mr-2 h-4 w-4" />
                <span>Billing</span>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Settings className="mr-2 h-4 w-4" />
                <span>Settings</span>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => {deauthenticateUser()}}>
                <LogOut className="mr-2 h-4 w-4" />
                <span>Log out</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="lg:hidden flex">
          <div>
            <div className="text-white !cursor-pointer" onClick={showDrawer}>
              <SvgsIcon type="bar-svg" />
            </div>
            <Drawer
              title={false}
              placement="left"
              onClose={onClose}
              open={open}
              className="!bg-dark-100 !text-white !cursor-pointer drawer-m"
            >
              <Menu className="flex flex-col main-header-m">
                {items.map((link) => {
                  return (
                    <MenuItem className="!text-white" key={link.label}>
                      <Link href={link.href}>{link.label}</Link>
                    </MenuItem>
                  );
                })}
              </Menu>
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
