"use client";
import "react-perfect-scrollbar/dist/css/styles.css";
import Layout from "antd/es/layout/layout";
import ReactPerfectScroll from "react-perfect-scrollbar";
import LeadBreakdown from "../components/lead-breakdown";
import DashboardContent from "./components/dashboard-content";
import { useEffect, useState } from "react";
import { fetchDashboardData, checkUserAuthentication } from "../functions";

type DashboardData = {
  upcoming_content: any,
  overall_performance: any,
  stats_cards: any,
  lead_report: any,
};

const Dashboard = () => {

  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);

  const checkAndRedirectIfNeeded = () => {
    if (!localStorage.getItem("accessToken")) {
      window.location.href = "/sign-in";
    } else { checkUserAuthentication(); }
  };

  useEffect(() => {
    checkAndRedirectIfNeeded()
    fetchDashboardData().then((data) => {
      setDashboardData(data);
    });
  }, []);

  return (
    <Layout className="!bg-white">
      <ReactPerfectScroll className="!max-h-[calc(100vh-180px)]">
        {/* {dashboardData && <DashboardContent initialUpcomingContent={dashboardData.upcoming_content} statsCards={dashboardData.stats_cards} overallPerformance={dashboardData.overall_performance} />} */}
        {dashboardData && <LeadBreakdown leadReport={dashboardData.lead_report} />}
      </ReactPerfectScroll>
    </Layout>
  );
};

export default Dashboard;
