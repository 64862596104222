import { Table, Tag, Tabs, Card, Avatar, List, Modal, Input, DatePicker, Select, Space, Button, Timeline, Spin, Skeleton } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState, useEffect } from "react";
import { fetchCallLeads, fetchFormLeads, fetchCombinedLeads } from "../functions";
import { PhoneOutlined, FormOutlined, UserOutlined, MailOutlined, CalendarOutlined, TagOutlined, ClockCircleOutlined, SearchOutlined, MessageOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Dayjs } from 'dayjs';
import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';

const columns = [
  {
    title: "Name",
    dataIndex: "lead_name",
  },
  {
    title: "Contact #",
    dataIndex: "lead_number",
  },
  {
    title: "Lead Channel",
    dataIndex: "lead_attribution",
    render: (attribution: any) => {
      if (attribution && attribution != 'Unknown') {
        return (
          <Tag className="!text-xs !py-1 !rounded-full !text-primary-100 !bg-primary-200 !border-primary-200 !font-body">
            {attribution}
          </Tag>
        );
      } else {
        return (
          <Tag className="!text-xs !py-1 !rounded-full !text-danger-200 !bg-danger-100 !border-danger-100 !font-body">
            Unknown
          </Tag>
        );
      }
    },
  },
  {
    title: "Start Time",
    dataIndex: "call_start",
  },
  {
    title: "Call Duration",
    dataIndex: "call_duration",
  },
  {
    title: "Call Summary",
    dataIndex: "call_summary",
    width: '20%',
    render: (item: any) => {
      return (<div style={{whiteSpace: "pre-line"}}>
        {item}
      </div>)
    }
  },
  // {
  //   title: "Customer Intent",
  //   dataIndex: "customer_intent",
  //   width: '20%',
  //   render: (item: any) => {
  //     return (<div style={{whiteSpace: "pre-line"}}>
  //       {item}
  //     </div>)
  //   }
  // },
  {
    title: "Call Status",
    dataIndex: "call_status",
    render: (status: any) => {
      if (status && status == 'no-answer') {
        return (
          <Tag className="!text-xs !py-1 !rounded-full !text-danger-200 !bg-danger-100 !border-danger-100 !font-body">
            Missed
          </Tag>
        );
      } else if (status && status == 'completed') {
        return (
          <Tag className="!text-xs !py-1 !rounded-full !text-success-100 !bg-success-200 !border-success-200 !font-body">
            Answered
          </Tag>
        );
      } else {
        return (<></>);
      }
    },
  },
];

const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
  console.log("params", pagination, filters, sorter, extra);
};

function convertObjectToLineItems(obj: any) {
  let result = '';
  for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
          result += `${key}: ${obj[key]}\n`;
      }
  }
  return result;
}

function processFormData(leads: any[]) {
  return leads.map(lead => {
    if (lead.latestInteraction.type === 'form' && lead.latestInteraction.data.form_data) {
      try {
        const formDataObj = lead.latestInteraction.data.form_data;
        lead.latestInteraction.data.form_data = convertObjectToLineItems(formDataObj);
      } catch (error) {
        console.error('Error parsing form_data:', error);
      }
    }
    lead.interactions.forEach((interaction: any) => {
      if (interaction.type === 'form' && interaction.data.form_data) {
        try {
          const formDataObj = interaction.data.form_data;
          interaction.data.form_data = convertObjectToLineItems(formDataObj);
        } catch (error) {
          console.error('Error parsing form_data in interaction:', error);
        }
      }
    });
    return lead;
  });
}

const LeadBreakdown = ({ leadReport }: any) => {

  const [combinedLeads, setCombinedLeads] = useState<any[]>([]);
  const [filteredLeads, setFilteredLeads] = useState<any[]>([]);
  const [filteredCallLeads, setFilteredCallLeads] = useState<any[]>([]);
  const [filteredFormLeads, setFilteredFormLeads] = useState<any[]>([]);
  const [filteredChatLeads, setFilteredChatLeads] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState('1');
  const [searchText, setSearchText] = useState('');
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'ascend' | 'descend'>('ascend');
  const [callFilters, setCallFilters] = useState({});
  const [formFilters, setFormFilters] = useState({});
  const [chatFilters, setChatFilters] = useState({});
  const [callLeadFilterOptions, setCallLeadFilterOptions] = useState<any>({});
  const [formLeadFilterOptions, setFormLeadFilterOptions] = useState<any>({});
  const [chatLeadFilterOptions, setChatLeadFilterOptions] = useState<any>({});

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const renderSkeletonItem = (index: number) => (
    <List.Item key={`skeleton-${index}`}>
      <Card hoverable className="w-full">
        <Skeleton active avatar paragraph={{ rows: 3 }} />
      </Card>
    </List.Item>
  );
  
  const renderSkeletonList = () => (
    <List
      grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 }}
      dataSource={Array.from({ length: 12 }, (_, index) => ({ key: `skeleton-${index}` }))}
      renderItem={(item, index) => renderSkeletonItem(index)}
    />
  );

  useEffect(() => {
    setIsLoading(true);
    fetchCombinedLeads().then((data) => {
      if (data) {
        // Process dates in .$date format
        const processedData = data.map((lead: any) => {
          const processInteraction = (interaction: any) => {
            const processedInteraction = { ...interaction };
            for (const key in processedInteraction.data) {
              if (processedInteraction.data[key] && processedInteraction.data[key].$date) {
                processedInteraction.data[key] = new Date(processedInteraction.data[key].$date).toISOString();
              }
            }
            return processedInteraction;
          };

          const processDate = (a: any) => {
            try{
              return new Date(a).toISOString()
            } catch (e) {
              return null;
            }
          }

          return {
            ...lead,
            date: lead.date && lead.date.$date ?  processDate(lead.date.$date) : lead.date,
            latestInteraction: processInteraction(lead.latestInteraction),
            interactions: lead.interactions.map(processInteraction)
          };
        });
  
        const finalProcessedData = processFormData(processedData);
        setCombinedLeads(finalProcessedData);
        
        // Extract filter options
        const callLeads = finalProcessedData.filter((lead: any) => lead.latestInteraction.type === 'call');
        const formLeads = finalProcessedData.filter((lead: any) => lead.latestInteraction.type === 'form');
        const chatLeads = finalProcessedData.filter((lead: any) => lead.latestInteraction.type === 'chat');
  
        const callAttributionOptions = new Set(callLeads.map((lead: any) => lead.latestInteraction.data.lead_attribution));
        const callStatusOptions = new Set(callLeads.map((lead: any) => lead.latestInteraction.data.call_status));
        setCallLeadFilterOptions({
          lead_attribution: callAttributionOptions,
          call_status: callStatusOptions
        });
  
        const formAttributionOptions = new Set(formLeads.map((lead: any) => lead.latestInteraction.data.attribution));
        const formNameOptions = new Set(formLeads.map((lead: any) => lead.latestInteraction.data.form_name));
        setFormLeadFilterOptions({
          attribution: formAttributionOptions,
          form_name: formNameOptions
        });
  
        const chatAttributionOptions = new Set(chatLeads.map((lead: any) => lead.latestInteraction.data.attribution));
        setChatLeadFilterOptions({
          attribution: chatAttributionOptions
        });
      }
      setIsLoading(false);
    }).catch((err) => {console.log(err); setIsLoading(false);});
  }, []);

  useEffect(() => {
    filterLeads();
  }, [combinedLeads, searchText, dateRange, sortField, sortOrder, callFilters, formFilters, chatFilters]);

  const filterLeads = () => {
    const filterAndSortLeads = (leads: any[]) => {
      return leads.filter((lead: any) => {
        const latestInteraction = lead.latestInteraction;
        const leadDate = new Date(lead.date);
        
        const matchesSearch = searchText === '' || [
          lead.name,
          lead.email,
          lead.phoneNumber,
          ...lead.interactions.flatMap((interaction: any) => Object.values(interaction.data))
        ].some((value) =>
          String(value).toLowerCase().includes(searchText.toLowerCase())
        );

        const matchesDateRange = !dateRange || (dateRange[0] && dateRange[1] && 
          leadDate >= dateRange[0].toDate() && leadDate <= dateRange[1].toDate());

        const matchesFilters = Object.entries({...callFilters, ...formFilters, ...chatFilters}).every(
          ([key, value]) => !value || latestInteraction.data[key] === value
        );

        return matchesSearch && matchesDateRange && matchesFilters;
      }).sort((a, b) => {
        if (!sortField) return 0;
        const aValue = a.latestInteraction.data[sortField];
        const bValue = b.latestInteraction.data[sortField];
        if (aValue < bValue) return sortOrder === 'ascend' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'ascend' ? 1 : -1;
        return 0;
      });
    };

    const filteredLeads = filterAndSortLeads(combinedLeads);
    setFilteredLeads(filteredLeads);
    setFilteredCallLeads(filteredLeads.filter(lead => lead.latestInteraction.type === 'call'));
    setFilteredFormLeads(filteredLeads.filter(lead => lead.latestInteraction.type === 'form'));
    setFilteredChatLeads(filteredLeads.filter(lead => lead.latestInteraction.type === 'chat'));
  };

  const showModal = (lead: any) => {
    setSelectedLead(lead);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getFormattedDate = (lead: any, type: 'call' | 'form' | 'chat') => {
    if (type === 'call') {
      return new Date(lead.call_start).toLocaleString();
    } else if (type === 'chat') {
      return new Date(lead.date_created).toLocaleString();
    } else {
      const dateField = lead.lead_date || lead.start_date || lead.created_date || lead.submission_date || lead.date;
      return dateField ? new Date(dateField).toLocaleString() : 'Date unknown';
    }
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    if (key === '2') {
      setFormFilters({});
      setChatFilters({});
    } else if (key === '3') {
      setCallFilters({});
      setChatFilters({});
    } else if (key === '4') {
      setCallFilters({});
      setFormFilters({});
    }
    setSearchText('');
  };

  const handleFilterChange = (field: string, value: string) => {
    if (activeTab === '1') {
      // For "All Leads" tab, update all filters
      setCallFilters(prev => ({ ...prev, [field]: value }));
      setFormFilters(prev => ({ ...prev, [field]: value }));
      setChatFilters(prev => ({ ...prev, [field]: value }));
    } else if (activeTab === '2') {
      setCallFilters(prev => ({ ...prev, [field]: value }));
    } else if (activeTab === '3') {
      setFormFilters(prev => ({ ...prev, [field]: value }));
    } else if (activeTab === '4') {
      setChatFilters(prev => ({ ...prev, [field]: value }));
    }
  };

  const clearFilters = () => {
    setCallFilters({});
    setFormFilters({});
    setChatFilters({});
    setSearchText('');
    setDateRange(null);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleDateRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    setDateRange(dates);
  };

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'ascend' ? 'descend' : 'ascend');
    } else {
      setSortField(field);
      setSortOrder('ascend');
    }
  };

  const renderLeadItem = (lead: any) => {
    const latestInteraction = lead.latestInteraction;
    const type = latestInteraction.type;
    const interactionData = latestInteraction.data;

    let displayName = lead.name !== 'Unknown' ? lead.name : (lead.email || lead.phoneNumber || 'Unknown');
    let contactInfo = lead.phoneNumber || lead.email || 'No contact info';

    // Define slightly darker background colors for call, form, and chat leads
    const avatarColor = type === 'call' ? '#D9EFFF' : type === 'form' ? '#EAF7E5' : '#FFE5E5';
    const avatarIconColor = type === 'call' ? '#0E77E5' : type === 'form' ? '#3DAB0A' : '#E50E0E';
    const avatarIcon = type === 'call' ? <PhoneOutlined /> : type === 'form' ? <FormOutlined /> : <MessageOutlined />;

    return (
      <List.Item onClick={() => showModal(lead)}>
        <Card hoverable className="w-full">
          <Card.Meta
            avatar={
              <Avatar 
                icon={avatarIcon} 
                style={{ 
                  backgroundColor: avatarColor, 
                  color: avatarIconColor,
                  fontSize: '1.2em'  // Slightly larger icon
                }}
              />
            }
            title={
              <div className="flex justify-between items-center">
                <span className="font-semibold truncate mr-2 flex-grow">{displayName}</span>
                <small className="text-gray-500 whitespace-nowrap flex-shrink-0">
                  {getFormattedDate(interactionData, type)}
                </small>
              </div>
            }
            description={
              <div className="mt-2">
                <p className="truncate">{contactInfo}</p>
                <div className="mt-1">
                  <Tag color={type === 'call' ? 'blue' : type === 'form' ? 'green' : 'red'} className="mr-1 mb-1">
                    {type === 'call' ? interactionData.lead_attribution : type === 'form' ? interactionData.attribution || interactionData.form_name : 'Chat'}
                  </Tag>
                  {type === 'call' && (
                    <Tag color={interactionData.call_status === 'completed' ? 'success' : 'error'} className="mb-1">
                      {interactionData.call_status === 'completed' ? 'Answered' : 'Missed'}
                    </Tag>
                  )}
                </div>
              </div>
            }
          />
          {type === 'call' && (
            <div className="mt-3">
              <p className="font-semibold">Call Summary:</p>
              <p className="text-sm line-clamp-3">{interactionData.call_summary}</p>
              <p className="mt-2"><strong>Duration:</strong> {interactionData.call_duration}</p>
            </div>
          )}
          {type === 'form' && (
            <div className="mt-3">
              <p className="font-semibold">Form Data:</p>
              <pre className="text-sm whitespace-pre-wrap break-words max-h-32 overflow-y-auto">{interactionData.form_data}</pre>
            </div>
          )}
          {type === 'chat' && (
            <div className="mt-3">
              <p className="font-semibold">Chat Message:</p>
              <p className="text-sm line-clamp-3">{interactionData.body}</p>
              <p className="mt-2"><strong>Date:</strong> {getFormattedDate(interactionData, 'chat')}</p>
            </div>
          )}
          <div className="mt-3">
            <p className="font-semibold">Interaction History:</p>
            <p className="text-sm">{lead.interactions.length} interaction(s)</p>
          </div>
        </Card>
      </List.Item>
    );
  };

  const renderModalContent = (lead: any) => {
    const latestInteraction = lead.latestInteraction;
    const isCallLead = latestInteraction.type === 'call';
    const isFormLead = latestInteraction.type === 'form';
    const isChatLead = latestInteraction.type === 'chat';
    const leadName = lead.name !== 'Unknown' ? lead.name : (lead.email || lead.phoneNumber || 'Unknown');
    const phoneNumber = lead.phoneNumber;
    const email = lead.email;

    return (
      <div className="lead-modal-content bg-gray-50 p-6 rounded-lg">
        <div className="lead-info-grid mb-6">
          <div className="lead-info-item bg-white p-4 rounded-md shadow-sm">
            <UserOutlined className="lead-info-icon text-blue-500" />
            <span className="lead-info-label font-bold mr-2">Name:</span>
            <span className="lead-info-value">{leadName}</span>
          </div>
          {phoneNumber && (
            <div className="lead-info-item bg-white p-4 rounded-md shadow-sm">
              <PhoneOutlined className="lead-info-icon text-green-500" />
              <span className="lead-info-label font-bold mr-2">Phone:</span>
              <span className="lead-info-value">{phoneNumber}</span>
            </div>
          )}
          {email && (
            <div className="lead-info-item bg-white p-4 rounded-md shadow-sm">
              <MailOutlined className="lead-info-icon text-purple-500" />
              <span className="lead-info-label font-bold mr-2">Email:</span>
              <span className="lead-info-value">{email}</span>
            </div>
          )}
          <div className="lead-info-item bg-white p-4 rounded-md shadow-sm">
            <CalendarOutlined className="lead-info-icon text-red-500" />
            <span className="lead-info-label font-bold mr-2">Date:</span>
            <span className="lead-info-value">{getFormattedDate(latestInteraction.data, isCallLead ? 'call' : isFormLead ? 'form' : 'chat')}</span>
          </div>
          <div className="lead-info-item bg-white p-4 rounded-md shadow-sm">
            <TagOutlined className="lead-info-icon text-yellow-500" />
            <span className="lead-info-label font-bold mr-2">Type:</span>
            <span className="lead-info-value">{isCallLead ? 'Call' : isFormLead ? 'Form' : 'Chat'}</span>
          </div>
        </div>
        {isCallLead ? (
          <div className="call-lead-details bg-white p-6 rounded-lg shadow-md">
            <div className="lead-info-item mb-4">
              <TagOutlined className="lead-info-icon text-indigo-500" />
              <span className="lead-info-label font-bold mr-2">Attribution:</span>
              <span className="lead-info-value">{latestInteraction.data.lead_attribution}</span>
            </div>
            <div className="lead-info-item mb-4">
              <span className="lead-info-label font-bold mr-2">Status:</span>
              <Tag color={latestInteraction.data.call_status === 'completed' ? 'success' : 'error'} className="text-sm px-2 py-1">
                {latestInteraction.data.call_status === 'completed' ? 'Answered' : 'Missed'}
              </Tag>
            </div>
            <div className="lead-info-item mb-4">
              <ClockCircleOutlined className="lead-info-icon text-cyan-500" />
              <span className="lead-info-label font-bold mr-2">Duration:</span>
              <span className="lead-info-value">{latestInteraction.data.call_duration}</span>
            </div>
            <div className="call-summary bg-gray-50 p-4 rounded-md">
              <h4 className="text-lg font-semibold mb-2">Call Summary:</h4>
              <p className="text-gray-700">{latestInteraction.data.call_summary}</p>
            </div>
          </div>
        ) : isFormLead ? (
          <div className="form-lead-details bg-white p-6 rounded-lg shadow-md">
            <div className="lead-info-item mb-4">
              <TagOutlined className="lead-info-icon text-indigo-500" />
              <span className="lead-info-label font-bold mr-2">Attribution:</span>
              <span className="lead-info-value">{latestInteraction.data.attribution || latestInteraction.data.form_name}</span>
            </div>
            <div className="form-data bg-gray-50 p-4 rounded-md">
              <h4 className="text-lg font-semibold mb-2">Form Data:</h4>
              <pre className="text-sm text-gray-700 whitespace-pre-wrap">{latestInteraction.data.form_data}</pre>
            </div>
          </div>
        ) : isChatLead ? (
          <div className="chat-lead-details bg-white p-6 rounded-lg shadow-md">
            <div className="lead-info-item mb-4">
              <TagOutlined className="lead-info-icon text-indigo-500" />
              <span className="lead-info-label font-bold mr-2">Attribution:</span>
              <span className="lead-info-value">Chat</span>
            </div>
            <div className="chat-data bg-gray-50 p-4 rounded-md">
              <h4 className="text-lg font-semibold mb-2">Chat Message:</h4>
              <p className="text-gray-700">{latestInteraction.data.body}</p>
            </div>
          </div>
        ) : null}
        <div className="interaction-timeline mt-6">
          <h4 className="text-lg font-semibold mb-2">Interaction Timeline:</h4>
          <Timeline>
            {lead.interactions.map((interaction: any, index: number) => (
              <Timeline.Item key={index}>
                <p><strong>{interaction.type === 'call' ? 'Call' : interaction.type === 'form' ? 'Form Submission' : 'Chat'}</strong></p>
                <p>{getFormattedDate(interaction.data, interaction.type)}</p>
                {interaction.type === 'call' && (
                  <>
                    <p>Duration: {interaction.data.call_duration}</p>
                    <p>Summary: {interaction.data.call_summary}</p>
                  </>
                )}
                {interaction.type === 'form' && (
                  <pre className="text-sm text-gray-700 whitespace-pre-wrap">{interaction.data.form_data}</pre>
                )}
                {interaction.type === 'chat' && (
                  <p>Message: {interaction.data.body}</p>
                )}
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
      </div>
    );
  };

  const renderFilters = (type: 'all' | 'call' | 'form' | 'chat') => {
    const fields = type === 'all' 
      ? ['lead_attribution', 'call_status', 'attribution', 'form_name']
      : type === 'call' 
        ? ['lead_attribution', 'call_status'] 
        : type === 'form'
          ? ['attribution', 'form_name']
          : ['attribution'];

    const filterOptions = type === 'all' 
      ? {...callLeadFilterOptions, ...formLeadFilterOptions, ...chatLeadFilterOptions}
      : type === 'call' ? callLeadFilterOptions : type === 'form' ? formLeadFilterOptions : chatLeadFilterOptions;

    const currentFilters: any = type === 'all' 
      ? {...callFilters, ...formFilters, ...chatFilters}
      : type === 'call' ? callFilters : type === 'form' ? formFilters : chatFilters;

    return (
      <Space className="mb-4" wrap>
        <Input
          placeholder="Search leads"
          prefix={<SearchOutlined />}
          onChange={(e) => handleSearch(e.target.value)}
          value={searchText}
        />
        <DatePicker.RangePicker 
          onChange={handleDateRangeChange}
          value={dateRange}
        />
        {fields.map((field) => (
          <Select
            key={field}
            style={{ width: 200 }}
            placeholder={`Filter by ${field.replace('_', ' ')}`}
            onChange={(value) => handleFilterChange(field, value)}
            value={currentFilters[field] || undefined}
          >
            {Array.from(filterOptions[field] || []).map((value: any) => (
              <Select.Option key={value} value={value}>{value}</Select.Option>
            ))}
          </Select>
        ))}
        <Button onClick={clearFilters}>Clear Filters</Button>
      </Space>
    );
  };

  return (
    <section className="lg:px-16 px-6 py-4">
      <Title className="!m-0 md:!text-2xl !text-xl !font-semibold !text-dark-100 !font-body">
        Lead Breakdown
      </Title>
      {isLoading ? <div className="mt-5">{renderSkeletonList()}</div>: (
      <Tabs defaultActiveKey="1" className="mt-5" onChange={handleTabChange}>
        <Tabs.TabPane 
          tab={
            <span className="flex items-center">
              <UserOutlined />
              <span className="ml-2">All Leads</span>
            </span>
          } 
          key="1"
        >
          {renderFilters('all')}
          <List
            grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 }}
            dataSource={[...filteredLeads]}
            renderItem={renderLeadItem}
          />
        </Tabs.TabPane>
        <Tabs.TabPane 
          tab={
            <span className="flex items-center">
              <PhoneOutlined />
              <span className="ml-2">Call Leads</span>
            </span>
          } 
          key="2"
        >
          {renderFilters('call')}
          <List
            grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 }}
            dataSource={filteredCallLeads}
            renderItem={renderLeadItem}
          />
        </Tabs.TabPane>
        <Tabs.TabPane 
          tab={
            <span className="flex items-center">
              <FormOutlined />
              <span className="ml-2">Form Leads</span>
            </span>
          } 
          key="3"
        >
          {renderFilters('form')}
          <List
            grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 }}
            dataSource={filteredFormLeads}
            renderItem={renderLeadItem}
          />
        </Tabs.TabPane>
        <Tabs.TabPane 
          tab={
            <span className="flex items-center">
              <MessageOutlined />
              <span className="ml-2">Chat Leads</span>
            </span>
          } 
          key="4"
        >
          {renderFilters('chat')}
          <List
            grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 }}
            dataSource={filteredChatLeads}
            renderItem={renderLeadItem}
          />
        </Tabs.TabPane>
      </Tabs>
      )}
      <Modal
        title={<h3 className="text-2xl font-semibold text-gray-800">Lead Details</h3>}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={null}
        bodyStyle={{ padding: 0 }}
      >
        {selectedLead && renderModalContent(selectedLead)}
      </Modal>
    </section>
  );
};

export default LeadBreakdown;
